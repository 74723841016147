import React, {createContext, useContext, ReactNode, useState, useEffect, SetStateAction} from "react";
import {Client} from "../types/userManagement";
import api, {apiV2} from "../api/axiosConfig";
import {
  UsersLocation,
  UsersLocationUser
} from "../features/operational-map/components/Map/hooks/useUserLocationMarkers";
import {useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult} from "react-query";
import moment, {Moment} from "moment";
import {IncidentDataTypes} from "../types/incident-data-types";
import {PaginatedResponse} from "../types/PaginatedResponse";
import {WorldSearchRecord} from "../features/operational-map/components/Map/hooks/useWorldSearchMarkers";
import {FacilityDataTypes} from "../types/facilitiy-data-types";
import {Geofences, SafetyZones} from "../features/sites/types";
import {UserDataTypes} from "../types/user-data-types";
import {UserLocationHistory} from "../features/operational-map/components/Map/services/useLocationHistory";
import generateRandomToken from "../util/generateToken";
import {Overlay} from "../types/overlay";


const availableUserLocationTypes = ["app", "trip", "facility", "satellite"] as const;
export type UserLocationType = typeof availableUserLocationTypes[number];

const availableIncidentLevels = ["major", "extreme"] as const;
export type IncidentLevel = typeof availableIncidentLevels[number];

const availableTripTypes = ["travel", "accommodation"] as const;
export type TripType = typeof availableTripTypes[number];

const accommodationSubTypes = [
  "accommodation:hotel",
  "accommodation:private_property",
  "accommodation:home",
  "accommodation:other",
  "accommodation:undefined"
]

const travelSubTypes = [
  "travel:flight",
  "travel:taxi",
  "travel:ferry",
  "travel:train",
  "travel:rental",
  "travel:private_car",
  "travel:bus",
  "travel:other",
  "travel:undefined",
]

const emptyPaginatedResult = {
  hasMore: false,
  items: [],
  total: 0,
  page: 1
}

const MapTypeIDs = [
  "roadmap",
  "terrain",
  "hybrid",
  "satellite",
]

export type MapTypeID = typeof MapTypeIDs[number];


type OperationalMapContextType = {
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;

  showTrackingMenu: boolean;
  setShowTrackingMenu: (showTracking: boolean) => void;


  client?: Client | undefined;
  setClient: (client: Client | undefined) => void;

  userQuery: UseQueryResult<UserLocation[]>;
  tripQuery: UseQueryResult<WorldSearchRecord[]>;
  incidentQuery: UseInfiniteQueryResult<PaginatedResponse<IncidentDataTypes>>;
  facilityQuery: UseInfiniteQueryResult<PaginatedResponse<FacilityDataTypes>>
  geofenceQuery: UseInfiniteQueryResult<PaginatedResponse<Geofences>>
  safetyZoneQuery: UseInfiniteQueryResult<PaginatedResponse<SafetyZones>>

  visibleInfoWindow: string|null;
  setVisibleInfoWindow: (key: string|null) => void;

  selectedUserLocationTypes: UserLocationType[];
  setSelectedUserLocationTypes: (userLocationTypes: UserLocationType[]) => void;
  availableUserLocationTypes: readonly UserLocationType[];

  selectedIncidentLevels: IncidentLevel[];
  setSelectedIncidentLevels: (incidentLevels: IncidentLevel[]) => void;

  selectedTripTypes: TripType[];
  setSelectedTripTypes: (tripTypes: TripType[]) => void;

  showFacilities: boolean;
  setShowFacilities: (showFacilities: boolean) => void;

  showGeofences: boolean;
  setShowGeofences: (showGeofences: boolean) => void;

  showSafetyZones: boolean;
  setShowSafetyZones: (showSafetyZones: boolean) => void;

  trackingUser: UserDataTypes|undefined;
  setTrackingUser: (user: UserDataTypes|undefined) => void;

  trackingPointsQuery: UseQueryResult<UserLocationHistory[]>;

  zoom: number;
  setZoom: (zoom: number) => void;

  center: google.maps.LatLngLiteral;
  setCenter: (center: google.maps.LatLngLiteral) => void;

  bounds: google.maps.LatLngBoundsLiteral | undefined;
  setBounds: (bounds: google.maps.LatLngBoundsLiteral) => void;

  overlayQuery: UseInfiniteQueryResult<PaginatedResponse<Overlay>>;
  selectedOverlays: Overlay[];
  setSelectedOverlays: (selectedOverlays: Overlay[]) => void;

  mapTypeID: MapTypeID|null;
  setMapTypeID: (mapTypeID: MapTypeID|null) => void;

}

const OperationalMapContext = createContext<OperationalMapContextType | undefined>(undefined)

type OperationalMapProviderProps = {
  children: ReactNode
}

export type UserLocation = {
  key: string;
  lat: number;
  lng: number;
  users: UsersLocationUser[];
  multiple: boolean;
}

export const OperationalMapProvider: React.FC<OperationalMapProviderProps> = (props) => {

  const [showFilters, setShowFilters] = useState(false);
  const [showTrackingMenu, setShowTrackingMenu] = useState(false);

  const [client, setClient] = useState<Client | undefined>();
  const [visibleInfoWindow, setVisibleInfoWindow] = useState<string|null>(null);

  const storedUserLocationTypes = localStorage.getItem("operationalMap:user");
  const [selectedUserLocationTypes, setSelectedUserLocationTypes] = useState<UserLocationType[]>(storedUserLocationTypes ? JSON.parse(storedUserLocationTypes) : availableUserLocationTypes);

  const storedIncidentLevels = localStorage.getItem("operationalMap:incidentLevel");
  const [selectedIncidentLevels, setSelectedIncidentLevels] = useState<IncidentLevel[]>(storedIncidentLevels ? JSON.parse(storedIncidentLevels ) : ["extreme"]);

  const storedTripTypes = localStorage.getItem("operationalMap:trip");
  const [selectedTripTypes, setSelectedTripTypes] = useState<TripType[]>(storedTripTypes ? JSON.parse(storedTripTypes) : []);

  const storedShowFacilities = localStorage.getItem("operationalMap:facilities");
  const [showFacilities, setShowFacilities] = useState(storedShowFacilities ? JSON.parse(storedShowFacilities) : false);

  const storedShowGeofences = localStorage.getItem("operationalMap:geofences");
  const [showGeofences, setShowGeofences] = useState(storedShowGeofences ? JSON.parse(storedShowGeofences) : false);

  const storedShowSafetyZones= localStorage.getItem("operationalMap:safetyZones");
  const [showSafetyZones, setShowSafetyZones] = useState(storedShowSafetyZones ? JSON.parse(storedShowSafetyZones) : false);

  const [trackingUser, setTrackingUser] = useState<UserDataTypes|undefined>();
  const [trackingUserToken, setTrackingUserToken] = useState<string|null>(null)

  const [trackingPoints, setTrackingPoints] = useState<UserLocationHistory[]>([])

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({lat: 0, lng:0})
  const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral>()
  const [zoom, setZoom] = useState(3)
  const [mapTypeID, setMapTypeID] = useState<MapTypeID|null>(null)

  const [selectedOverlays, setSelectedOverlays] = useState<Overlay[]>([]);

  const fetchOverlays = async(page = 1): Promise<PaginatedResponse<Overlay>> => {
    const params = {
      clientID: client ? client.id : null,
      page,
      rpp: 200
    }
    const result = await api.get<PaginatedResponse<Overlay>>("map-overlays", {params})
    
    return result.data
  }
  
  const overlayQuery =  useInfiniteQuery(
    ["operational-map-overlays", client],
    ({pageParam = 1}) => fetchOverlays(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
    }
  )

  useEffect(() => {
    if (overlayQuery.hasNextPage && !overlayQuery.isFetchingNextPage) {
      overlayQuery.fetchNextPage();
    }
  }, [overlayQuery.hasNextPage, overlayQuery.isFetchingNextPage, overlayQuery.fetchNextPage]);
  
  

  const fetchUsersLocations = async () => {
    console.log("fetching user locations")
    const locations = await api.get<UsersLocation[]>(
      "ops/user-locations-clustered",
      {
        params: {
          sources: selectedUserLocationTypes.join(","),
          clientID: client ? client.id : null,
        },
      })

    const users: UserLocation[] = []

    locations.data.forEach((l) => {
      const key = l.users.map(u => u._id).join("_")
      users.push({
        key,
        lat: l.id[1],
        lng: l.id[0],
        multiple: l.users.length > 1,
        users: l.users,
      })
    })

    return users

  };

  const userQuery = useQuery(
    [
      "operational-map-users",
      client,
      selectedUserLocationTypes,
    ],
    fetchUsersLocations,
    {
      refetchInterval: 60000,
    }
  )

  const fetchIncidents = async (page: number): Promise<PaginatedResponse<IncidentDataTypes>> => {
    console.log("fetching incidents", page)

    if(selectedIncidentLevels.length == 0){
      return {
        hasMore: false,
        items: [],
        page: 1,
        total: 0,
      }
    }

    const rpp = 200
    const startTime = moment().subtract(1, "week").toISOString()
    const endTime = moment().add(1, "week").toISOString()
    const severity = selectedIncidentLevels.join(",")

    const params = {
      rpp,
      startTime,
      endTime,
      severity
    }

    const response = await api.get<PaginatedResponse<IncidentDataTypes>>(`/incidents?page=${page}`,{params})
    return response.data;
  }

  const incidentQuery = useInfiniteQuery(
      ["operational-map-incidents", selectedIncidentLevels],
      ({pageParam = 1}) => fetchIncidents(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
      }
  )

  useEffect(() => {
    if (incidentQuery.hasNextPage && !incidentQuery.isFetchingNextPage) {
      incidentQuery.fetchNextPage();
    }
  }, [incidentQuery.hasNextPage, incidentQuery.isFetchingNextPage, incidentQuery.fetchNextPage]);


  const fetchFacilities = async (page: number): Promise<PaginatedResponse<FacilityDataTypes>> => {

    console.log("fetching facilities", page)

    if(!showFacilities){
      return emptyPaginatedResult;
    }

    const params = {
      clientID: client ? client.id : null,
      rpp: 200
    }

    const response =  await api.get<PaginatedResponse<FacilityDataTypes>>(`/facilities?page=${page}`, { params })
    return response.data
  };

  const facilityQuery = useInfiniteQuery(
    ["operational-map-facilities", showFacilities],
    ({pageParam = 1}) => fetchFacilities(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
    }
  )

  useEffect(() => {
    if (facilityQuery.hasNextPage && !facilityQuery.isFetchingNextPage) {
      facilityQuery.fetchNextPage();
    }
  }, [facilityQuery.hasNextPage, facilityQuery.isFetchingNextPage, facilityQuery.fetchNextPage]);

  const fetchGeofences= async (page: number): Promise<PaginatedResponse<Geofences>> => {

    console.log("fetching geofences", page)

    if(!showGeofences){
      return emptyPaginatedResult;
    }

    const params = {
      clientID: client ? client.id : null,
      rpp: 200
    }

    const response =  await api.get<PaginatedResponse<Geofences>>(`/geofences?page=${page}`, { params })
    return response.data
  };

  const geofenceQuery = useInfiniteQuery(
    ["operational-map-geofences", showGeofences],
    ({pageParam = 1}) => fetchGeofences(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
    }
  )

  useEffect(() => {
    if (geofenceQuery.hasNextPage && !geofenceQuery.isFetchingNextPage) {
      geofenceQuery.fetchNextPage();
    }
  }, [geofenceQuery.hasNextPage, geofenceQuery.isFetchingNextPage, geofenceQuery.fetchNextPage]);

  const fetchSafetyZones= async (page: number): Promise<PaginatedResponse<SafetyZones>> => {

    console.log("fetching safetyZones", page)

    if(!showSafetyZones){
      return emptyPaginatedResult;
    }
    const params = {
      clientID: client ? client.id : null,
      rpp: 200
    }

    const response =  await api.get<PaginatedResponse<SafetyZones>>(`/safety-zones?page=${page}`, { params })
    return response.data
  };

  const safetyZoneQuery = useInfiniteQuery(
    ["operational-map-safetyZones", showSafetyZones],
    ({pageParam = 1}) => fetchSafetyZones(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.hasMore ? allPages.length + 1 : undefined,
    }
  )

  useEffect(() => {
    if (safetyZoneQuery.hasNextPage && !safetyZoneQuery.isFetchingNextPage) {
      safetyZoneQuery.fetchNextPage();
    }
  }, [safetyZoneQuery.hasNextPage, safetyZoneQuery.isFetchingNextPage, safetyZoneQuery.fetchNextPage]);




  const fetchTrips = async (): Promise<WorldSearchRecord[]> => {
    console.log("fetching trips");
    const subtypes = []
    if(selectedTripTypes.includes("travel")){
      subtypes.push(...travelSubTypes)
    }

    if(selectedTripTypes.includes("accommodation")){
      subtypes.push(...accommodationSubTypes)
    }

    if(subtypes.length == 0){
      return []
    }

    const params = {
      clientID: client ? client.id : null,
      subtypes: subtypes.join(","),
    }

    const response = await apiV2.get<WorldSearchRecord[]>("/map/world-search", {params})

    return response.data
  }

  const tripQuery = useQuery(
    ["operational-map-trips", client, selectedTripTypes],
    fetchTrips,
  )




  const handleSetUserLocationTypes = (v: UserLocationType[])=> {
    localStorage.setItem("operationalMap:user", JSON.stringify(v))
    setSelectedUserLocationTypes(v);
  }

  const handleSetIncidentLevels = (v: IncidentLevel[])=> {
    localStorage.setItem("operationalMap:incidentLevel", JSON.stringify(v))
    setSelectedIncidentLevels(v);
  }

  const handleSetTripTypes = (v: TripType[])=> {
    localStorage.setItem("operationalMap:trip", JSON.stringify(v))
    setSelectedTripTypes(v);
  }


  const handleSetShowFacilities = (v: boolean) => {
    localStorage.setItem("operationalMap:facilities", JSON.stringify(v))
    setShowFacilities(v);
  }

  const handleSetShowGeofences = (v: boolean) => {
    localStorage.setItem("operationalMap:geofences", JSON.stringify(v))
    setShowGeofences(v);
  }
  const handleSetShowSafetyZones = (v: boolean) => {
    localStorage.setItem("operationalMap:safetyZones", JSON.stringify(v))
    setShowSafetyZones(v);
  }

  const handleSetTrackingUser = (u: UserDataTypes|undefined) => {
    setTrackingPoints([])
    if(u) {
      setTrackingUserToken(`${u.id}_${generateRandomToken(12)}`)
    }
    setTrackingUser(u)
  }

  const fetchUserTracking = async (): Promise<UserLocationHistory[]> => {
    console.log("fetching user tracking");

    if(!trackingUser){
      console.log("no user selected to track")
      return []
    }

    const params = {
      token: trackingUserToken
    }

    const response = await api.get<UserLocationHistory[]>(`ops/user-location-feed/${trackingUser.id}`, {params})

    const result = [...trackingPoints, ...response.data]

    if(response.data.length) {
      setTrackingPoints(result)
      const last = result[result.length - 1]

      if (last) {
        setCenter({
          lat: last.coordinates[1],
          lng: last.coordinates[0],
        })

        setZoom(18)
      }
    }
    return result

  }

  const trackingPointsQuery = useQuery(
    ["operational-map-tracking", trackingUser],
    fetchUserTracking,
    {
      refetchInterval: 5000,
    }
  )



  return <OperationalMapContext.Provider value={{
    showFilters,
    setShowFilters,
    showTrackingMenu,
    setShowTrackingMenu,
    client,
    setClient,
    userQuery,
    tripQuery,
    incidentQuery,
    facilityQuery,
    safetyZoneQuery,
    geofenceQuery,
    visibleInfoWindow,
    setVisibleInfoWindow,
    availableUserLocationTypes,
    selectedUserLocationTypes,
    setSelectedUserLocationTypes: handleSetUserLocationTypes,
    selectedIncidentLevels,
    setSelectedIncidentLevels: handleSetIncidentLevels,
    selectedTripTypes,
    setSelectedTripTypes: handleSetTripTypes,
    showFacilities,
    setShowFacilities: handleSetShowFacilities,
    showGeofences,
    setShowGeofences : handleSetShowGeofences,
    showSafetyZones,
    setShowSafetyZones : handleSetShowSafetyZones,
    trackingUser,
    setTrackingUser: handleSetTrackingUser,
    trackingPointsQuery,
    zoom,
    setZoom,
    center,
    setCenter,
    bounds,
    setBounds,
    overlayQuery,
    selectedOverlays,
    setSelectedOverlays,
    mapTypeID,
    setMapTypeID,
  }}
  >
    {props.children}
  </OperationalMapContext.Provider>
}

export const useOperationalMapContext = (): OperationalMapContextType => {
  const context = useContext(OperationalMapContext);
  if (!context) {
    throw new Error("useTimeZoneSelectContext must be used within a TimeZoneSelectProvider");
  }
  return context;
};

