import React, {ChangeEvent, useRef, useState} from "react"
import {APIProvider, InfoWindow, Map, MapMouseEvent} from "@vis.gl/react-google-maps";
import {
  IncidentLevel,
  TripType,
  useOperationalMapContext,
  UserLocationType
} from "../../providers/OperationalMapProvider";
import {useOnClickOutside} from "usehooks-ts";
import {ClientSelectSingle} from "../../components/form-v2/searchable-select/ClientSelect";
import {BlueButton} from "../../components/ui/Buttons";
import {Client} from "../../types/userManagement";
import DoneIcon from "@mui/icons-material/Done";
import {UserMarker} from "./usermarker";
import {IncidentMarker} from "./incidentmarker";
import {TripMarker} from "./tripmarker";
import {MapToolbar} from "./toolbar";
import {FacilityMarker} from "./facilitymarker";
import {ZoneMarker} from "./zonemarker";
import {UserSelectSingle} from "../../components/form-v2/searchable-select/UserSelect";
import {UserDataTypes} from "../../types/user-data-types";
import {TrackingMarker} from "./trackingmarker";
import {OverlaySelect} from "./overlayselect";
import {Overlay} from "./overlay";
import {UsersClustered} from "./usersclustered";
import {IncidentsClustered} from "./incidentsclustered";
import {TripsClustered} from "./tripsclustered";
import {FacilitiesClustered} from "./facilitiesclustered";


export const OperationalMapV2: React.FC = () => {

  const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;
  const ctx = useOperationalMapContext();
  const [showCoordinates, setShowCoordinates] = React.useState<google.maps.LatLngLiteral>();

  const handleRightClick = (e: MapMouseEvent) => {
    if(e.detail.latLng){
      setShowCoordinates(e.detail.latLng)
    }
    e.stop()
  }


  return (
    <>
      <style>
        {` 
         .gm-style-iw-chr > button {
            // display: none !important;
          }
        `}
      </style>
      <div className="flex-1 relative h-full">


        <APIProvider apiKey={googleMapsApiKey}>
          <MapToolbar/>
          {ctx.showFilters && <FilterOptions/>}
          {ctx.showTrackingMenu && <TrackingMenu/>}

          <Map
            onContextmenu={handleRightClick}
            id={"operational-map"}
            onClick={() => {
              ctx.setVisibleInfoWindow(null)
              setShowCoordinates(undefined)
            }}
            mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
            style={{}}
            defaultCenter={{lat: 52, lng: -1}}
            zoom={ctx.zoom}
            onZoomChanged={(e)=> ctx.setZoom(e.detail.zoom)}
            center={ctx.center}
            onCameraChanged={(e)=> {
              ctx.setCenter(e.detail.center)
              ctx.setBounds(e.detail.bounds)
            }}
            gestureHandling={"greedy"}
            disableDefaultUI={true}
            minZoom={3}
            fullscreenControl={true}
            zoomControl={true}
            mapTypeId={ctx.mapTypeID || "roadmap"}

          >

            {ctx.trackingUser && ctx.trackingPointsQuery.data && ctx.trackingPointsQuery.data.map((p, i, arr)=>{
              const next = arr[i + 1] || null;
              const key = `tracking_${p.startTime}`
              return <TrackingMarker current={p} next={next} infoKey={key} key={key} showInfoWindow={ctx.visibleInfoWindow == key} />
            })}

            <IncidentsClustered/>
            <TripsClustered/>
            <UsersClustered/>
            <FacilitiesClustered/>

            {ctx.geofenceQuery.data?.pages.flatMap(p => p.items).map(z =>(
              <ZoneMarker key={z.id} infoKey={z.id}  type="geofence" zone={z} showInfoWindow={ctx.visibleInfoWindow == z.id} />
            ))}

            {ctx.safetyZoneQuery.data?.pages.flatMap(p => p.items).map(z =>(
              <ZoneMarker key={z.id} infoKey={z.id}  type="safetyZone" zone={z} showInfoWindow={ctx.visibleInfoWindow == z.id} />
            ))}

            {ctx.selectedOverlays.map(o => <Overlay key={o.id} overlay={o}/>)}

            {showCoordinates && <InfoWindow position={showCoordinates}>
              <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
                <div className={"text-base font-semibold"}>Latitude</div>
                <div className={"text-base capitalize"}>{showCoordinates.lat}</div>
                   <div className={"text-base font-semibold"}>Longitude</div>
                <div className={"text-base capitalize"}>{showCoordinates.lng}</div>

              </div>
            </InfoWindow>}


          </Map>
        </APIProvider>

      </div>
    </>
  );
}


const UnclustereredLayer: React.FC = () => {
  const ctx = useOperationalMapContext();

  return <>
    {ctx.trackingUser && ctx.trackingPointsQuery.data && ctx.trackingPointsQuery.data.map((p, i, arr) => {
      const next = arr[i + 1] || null;
      const key = `tracking_${p.startTime}`
      return <TrackingMarker current={p} next={next} infoKey={key} key={key}
                             showInfoWindow={ctx.visibleInfoWindow == key}/>
    })}
    {!ctx.trackingUser && <>
      {ctx.userQuery.data && ctx.userQuery.data.map((l) => (
        <UserMarker key={l.key} infoKey={l.key} lat={l.lat} lng={l.lng} users={l.users} multiple={l.multiple}
                    showInfoWindow={ctx.visibleInfoWindow == l.key}/>
      ))}

      {ctx.incidentQuery.data?.pages.flatMap((p) => p.items).map(i => (
        <IncidentMarker key={i.id} incident={i} infoKey={i.id} showInfoWindow={ctx.visibleInfoWindow == i.id}/>
      ))}

      {ctx.tripQuery.data && ctx.tripQuery.data.map((t)=> (
        <TripMarker key={t.id} record={t} showInfoWindow={ctx.visibleInfoWindow == t.id}/>
      ))}

      {ctx.facilityQuery.data?.pages.flatMap(p => p.items).map(f =>(
        <FacilityMarker key={f.id} infoKey={f.id}  facility={f} showInfoWindow={ctx.visibleInfoWindow == f.id} />
      ))}


      {ctx.geofenceQuery.data?.pages.flatMap(p => p.items).map(z =>(
        <ZoneMarker key={z.id} infoKey={z.id}  type="geofence" zone={z} showInfoWindow={ctx.visibleInfoWindow == z.id} />
      ))}

      {ctx.safetyZoneQuery.data?.pages.flatMap(p => p.items).map(z =>(
        <ZoneMarker key={z.id} infoKey={z.id}  type="safetyZone" zone={z} showInfoWindow={ctx.visibleInfoWindow == z.id} />
      ))}

      {ctx.selectedOverlays.map(o => <Overlay key={o.id} overlay={o}/>)}
    </>}
  </>


}


const TrackingMenu: React.FC = () => {
  const ctx = useOperationalMapContext()
  const ref = useRef(null)

  const [client, setClient] = useState<Client|undefined>(undefined)

  const [user, setUser] = useState<UserDataTypes|undefined>(ctx.trackingUser)

  useOnClickOutside(ref, () => {
    ctx.setShowTrackingMenu(false)
  })

  const stopTracking = () => {
    ctx.setTrackingUser(undefined)
    ctx.setShowTrackingMenu(false)
  }

  const startTracking = () => {
    ctx.setTrackingUser(user)
  }

  return <div ref={ref} className={"p-4 rounded shadow bg-white absolute top-20 left-4 z-[95] w-[20em] flex flex-col gap-4"}>
    <div>User Tracking History</div>
    <hr/>
    <ClientSelectSingle disabled={!!ctx.trackingUser} client={client} onChange={setClient}/>
    <UserSelectSingle disabled={!!ctx.trackingUser} user={user} onChange={setUser} clientID={client ? client.id : null} />
    {!ctx.trackingUser && user &&  <BlueButton text={"Start Tracking"} onClick={startTracking}/>}
    {ctx.trackingUser && <BlueButton text={"Stop Tracking"} onClick={stopTracking}/>}

  </div>



}


const FilterOptions: React.FC = () => {
  const ctx = useOperationalMapContext()
  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    ctx.setShowFilters(false)
  })

  const [selectedUserLocationTypes, setSelectedUserLocationTypes] = useState(ctx.selectedUserLocationTypes)
  const [selectedTripTypes, setSelectedTripTypes] = useState(ctx.selectedTripTypes)
  const [selectedIncidentLevels, setSelectedIncidentLevels] = useState(ctx.selectedIncidentLevels)

  const [showFacilities, setShowFacilities] = useState(ctx.showFacilities)
  const [showGeofences, setShowGeofences] = useState(ctx.showGeofences)
  const [showSafetyZones, setShowSafetyZones] = useState(ctx.showSafetyZones)







  const [client, setClient] = useState<Client|undefined>(ctx.client)

  const submit = ()=> {
    ctx.setClient(client)
    ctx.setShowFilters(false)
    ctx.setSelectedUserLocationTypes(selectedUserLocationTypes)
    ctx.setSelectedIncidentLevels(selectedIncidentLevels)
    ctx.setSelectedTripTypes(selectedTripTypes)
    ctx.setShowFacilities(showFacilities)
    ctx.setShowGeofences(showGeofences)
    ctx.setShowSafetyZones(showSafetyZones)
  }

  const reset = () => {
    ctx.setClient(undefined)
    ctx.setShowFilters(false)
    ctx.setSelectedUserLocationTypes(["app", "trip", "satellite", "facility"])
    ctx.setSelectedIncidentLevels(["extreme"])
    ctx.setSelectedTripTypes([])
    ctx.setShowFacilities(false)
    ctx.setShowGeofences(false)
    ctx.setShowSafetyZones(false)
  }

  const onUserChange = (key: UserLocationType) => {
    return (value: boolean) => {
      if(value){
        setSelectedUserLocationTypes([
          ...selectedUserLocationTypes,
          key
        ])
      } else {
      setSelectedUserLocationTypes(selectedUserLocationTypes.filter(v => v != key))
      }
    }
  }


  const onIncidentChange = (key: IncidentLevel) => {
    return (value: boolean) => {
      if(value){
        setSelectedIncidentLevels([
          ...selectedIncidentLevels,
          key
        ])
      } else {
        setSelectedIncidentLevels(selectedIncidentLevels.filter(v => v != key))
      }
    }
  }

  const onTripChange = (key: TripType) => {
    return (value: boolean) => {
      setSelectedTripTypes(value ? [
        ...selectedTripTypes,
        key
      ] : selectedTripTypes.filter(v => v != key))
    }
  }
  


  return <div ref={ref} className={"p-4 rounded shadow bg-white absolute top-20 left-4 z-[1000] w-[20em] flex flex-col gap-4"}>
    <div>Operational Map Filters</div>
    <hr/>
    <ClientSelectSingle client={client} onChange={setClient}/>
    
    <CheckBoxGroup label={"User Type"}>
      <CheckBox label={"App Users"} checked={selectedUserLocationTypes.includes("app")} onChange={onUserChange("app")}/>
      <CheckBox label={"Travellers"} checked={selectedUserLocationTypes.includes("trip")} onChange={onUserChange("trip")}/>
      <CheckBox label={"Static Employees"} checked={selectedUserLocationTypes.includes("facility")} onChange={onUserChange("facility")}/>
      <CheckBox label={"Satellite"} checked={selectedUserLocationTypes.includes("satellite")} onChange={onUserChange("satellite")}/>
    </CheckBoxGroup>


    <CheckBoxGroup label={"Trip"}>
      <CheckBox label={"Travel"} checked={selectedTripTypes.includes("travel")} onChange={onTripChange("travel")}/>
      <CheckBox label={"Accommodation"} checked={selectedTripTypes.includes("accommodation")} onChange={onTripChange("accommodation")}/>
    </CheckBoxGroup>

    <CheckBoxGroup label={"Sites"}>
      <CheckBox label={"Facilities"} checked={showFacilities} onChange={setShowFacilities}/>
      <CheckBox label={"Geofences"} checked={showGeofences} onChange={setShowGeofences}/>
      <CheckBox label={"Safety Zones"} checked={showSafetyZones} onChange={setShowSafetyZones}/>
    </CheckBoxGroup>

    <CheckBoxGroup label={"Incidents"}>
      <CheckBox label={"Major"} checked={selectedIncidentLevels.includes("major")} onChange={onIncidentChange("major")}/>
      <CheckBox label={"Extreme"} checked={selectedIncidentLevels.includes("extreme")} onChange={onIncidentChange("extreme")}/>
    </CheckBoxGroup>

    <CheckBoxGroup label={"Overlays"}>
      <OverlaySelect/>
    </CheckBoxGroup>

    <div className={"flex gap-4"}>
      <BlueButton text={"Filter"} onClick={submit}/>
      <BlueButton text={"Reset"} onClick={reset}/>
    </div>
    </div>
}

type CheckBoxGroupProps = {
  label: string,
  children: React.ReactNode
}

const CheckBoxGroup: React.FC<CheckBoxGroupProps>= (props) => {
  return <div className={"flex flex-col gap-2"}>
    <h4 className={"font-semibold"}>{props.label}</h4>
    {props.children}
  </div>
}

type CheckBoxProps = {
  label?: string,
  checked ?: boolean,
  onChange?: (checked: boolean) => void

}

const CheckBox: React.FC<CheckBoxProps> = (props)=> {

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(!props.onChange) return
    props.onChange(e.target.checked)
  }

  return <div className="flex items-center gap-4 text-sm">
    <div className="relative w-4 h-4 flex items-center justify-center">
      <input
        checked={props.checked}
        type="checkbox"
        className={"h-full w-full z-10 relative opacity-0"}
        onChange={onChange}

      />
      <button
        type="button"
        className="w-5 h-5 absolute border top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex items-center justify-center"
      >
        {props.checked ? <DoneIcon style={{fontSize: "14px"}}/> : null}
      </button>
    </div>

    <span className="text-ellipsis overflow-hidden whitespace-nowrap">
        {props.label}
      </span>
  </div>
}