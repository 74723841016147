import React, {memo, useCallback} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {AdvancedMarker, InfoWindow, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";
import {FacilityDataTypes} from "../../types/facilitiy-data-types";
import {useBoundsCheck} from "./bounds";

type FacilityMarkerProps = {
  facility: FacilityDataTypes;
  infoKey: string;
  showInfoWindow: boolean;
}

export const FacilityMarker: React.FC<FacilityMarkerProps> = memo((props) => {
    const ctx = useOperationalMapContext();
    const [markerRef, marker] = useAdvancedMarkerRef();

    const openInfo = useCallback(() => {
      ctx.setVisibleInfoWindow(props.infoKey);
    }, [ctx, props.infoKey]);

    const outOfBounds = useBoundsCheck(
      props.facility.position.coordinates[0],
      props.facility.position.coordinates[1],
    )

    if(outOfBounds){
      return null;
    }


    return (
      <>
        <AdvancedMarker position={{ lat: props.facility.position.coordinates[1], lng: props.facility.position.coordinates[0] }} onClick={openInfo} ref={markerRef}>
          <img src={"/assets/mapicons/facility.png"} width={24} height={24} alt={"Facility Icon"} />
        </AdvancedMarker>
        {props.showInfoWindow && (
          <InfoWindow anchor={marker}>
            <h3 className={"text-center text-lg font-semibold"}>Facility Details</h3>
            <hr/>
            <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
              <div className={"text-base font-semibold"}>Name</div>
              <div className={"text-base capitalize"}>{props.facility.name}</div>
              <div className={"text-base font-semibold"}>Client</div>
              <div className={"text-base capitalize"}>{props.facility.clientName}</div>
            </div>
          </InfoWindow>
        )}
      </>
    )
      ;
  })
;

FacilityMarker.displayName = "FacilityMarker"