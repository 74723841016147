import React, {memo, useCallback, useMemo} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {AdvancedMarker, InfoWindow, useAdvancedMarkerRef} from "@vis.gl/react-google-maps";
import {FacilityDataTypes} from "../../types/facilitiy-data-types";
import {Geofences, PolygonLocation, SafetyZones} from "../sites/types";
import {Circle} from "./circle";
import {Polygon} from "./polygon";
import moment from "moment";
import {useTimeZoneSelectContext} from "../../providers/TimeZoneSelectProvider";
import {useBoundsCheck} from "./bounds";
import {getPolygonCenter} from "./math";

type ZoneMarkerProps = {
  zone: Geofences | SafetyZones;
  type: "geofence" | "safetyZone"
  infoKey: string;
  showInfoWindow: boolean;
}







export const ZoneMarker: React.FC<ZoneMarkerProps> = memo((props) => {
  const ctx = useOperationalMapContext();
  const tzCTX = useTimeZoneSelectContext()
  const [markerRef, marker] = useAdvancedMarkerRef();

  const openInfo = useCallback(() => {
    ctx.setVisibleInfoWindow(props.infoKey);
  }, [ctx, props.infoKey]);

  const {zone}= props;

  const center = useMemo(() => {
    return zone.location.type == "Point" ?
      { lat: zone.location.coordinates[1], lng: zone.location.coordinates[0] } :
      getPolygonCenter(zone.location);
  }, [zone.location]);



    const title = props.type == "geofence" ? "Geofence Details" : "Safety Zone Details";

    const strokeColor = useMemo(() => {
      if(props.type == "safetyZone"){
        return "rgba(30,255,154, 0.8)"
      }
      return "rgba(255,115,0,0.8)"
    },[props.type])

    const fillColor = useMemo(() => {
      if(props.type == "safetyZone"){
        return "rgba(0,255,149,0.2)"
      }
      return "rgba(255,115,0,0.2)"
    },[props.type])


    return (
      <>

        {zone.location.type == "Point" && <Circle
          onClick={openInfo}
          strokeColor={strokeColor}
          strokeOpacity={1}
          fillColor={fillColor}
          fillOpacity={1}
          center={center}
          strokeWeight={1}
          radius={zone.location.properties.radius}
        />}

        {zone.location.type == "Polygon" && <Polygon
            onClick={openInfo}
            strokeColor={strokeColor}
            strokeOpacity={1}
            fillColor={fillColor}
            fillOpacity={1}
            strokeWeight={1}
          coordinates={zone.location.coordinates[0]}
        />}


         <AdvancedMarker position={center} onClick={openInfo} ref={markerRef}>
          <div></div>
         </AdvancedMarker>
         {props.showInfoWindow && (
          <InfoWindow anchor={marker}>
            <h3 className={"text-center text-lg font-semibold"}>{title}</h3>
            <hr/>
            <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
              <div className={"text-base font-semibold"}>Name</div>
              <div className={"text-base capitalize"}>{zone.name}</div>
              <div className={"text-base font-semibold"}>Client</div>
              <div className={"text-base capitalize"}>{zone.clientName}</div>


              {zone.alwaysActive && <>
                  <div className={"text-base font-semibold"}>Starts At</div>
                  <div className={"text-base capitalize"}>
                      Permanent
                  </div>
                  <div className={"text-base font-semibold"}>Expires At</div>
                  <div className={"text-base capitalize"}>
                      Permanent
                  </div>
              </>
              }

              {!zone.alwaysActive && <>
                  <div className={"text-base font-semibold"}>Starts At</div>
                  <div className={"text-base capitalize"}>
                    {moment.tz(zone.startsAt, tzCTX.timeZone).format("DD/MM/YYYY HH:mm Z z")}
                  </div>
                  <div className={"text-base font-semibold"}>Expires At</div>
                  <div className={"text-base capitalize"}>
                    {moment.tz(zone.expiresAt, tzCTX.timeZone).format("DD/MM/YYYY HH:mm Z z")}
                  </div>
              </>}
            </div>
          </InfoWindow>
         )}
      </>
    )
      ;
  })
;

ZoneMarker.displayName = "ZoneMarker"