import React, {memo, useCallback} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {AdvancedMarker, InfoWindow, Marker, useAdvancedMarkerRef, useMarkerRef} from "@vis.gl/react-google-maps";
import {BlueButton} from "../../components/ui/Buttons";
import moment from "moment/moment";
import {UserMarkerProps} from "./usermarker";

export const SingleUserMarker: React.FC<UserMarkerProps> = memo((props) => {
    const ctx = useOperationalMapContext();
    const [markerRef, marker] = useMarkerRef();

    const openInfo = useCallback(() => {
      ctx.setVisibleInfoWindow(props.infoKey);
    }, [ctx, props.infoKey]);

    const user = props.users[0]
    const source = user.source === "facility" ? "Stationed Facility" : user.source

    return (
      <>
        <Marker
          ref={markerRef}
          position={{lat: props.lat, lng: props.lng }}
          onClick={openInfo}
          icon={{
            url: "/assets/mapicons/user.png",
          }}
        />
        {props.showInfoWindow && (
          <InfoWindow anchor={marker}>
            <h3 className={"text-center text-lg font-semibold"}>User Details</h3>
            <hr/>
            <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
              <div className={"text-base font-semibold"}>Name</div>
              <div className={"text-base capitalize"}>{user.firstName} {user.lastName}</div>

              <div className={"text-base font-semibold"}>Email</div>
              <div className={"text-base capitalize"}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </div>

              {user.phoneNumbers && user.phoneNumbers.length != 0 && <><div className={"text-base font-semibold"}>Phone Number/s</div>
                  <div className={"text-base capitalize"}>
                    {user.phoneNumbers.map((p, i) => (
                      <a key={i} className={"block"} href={`tel:${p.number}`}>{p.number}</a>
                    ))}
                  </div>
              </>}

              <div className={"text-base font-semibold"}>Source</div>
              <div className={"text-base capitalize"}>{source}</div>
            </div>

            <div className={"mt-2"}>
              <BlueButton text={"Show World Search"} onClick={() => {
                window.open(`world-search?userID=${user._id}&startDate=${moment().subtract(1, "month").format("YYYY-MM-DD")}&endDate=${moment().add(1, "month").format("YYYY-MM-DD")}`, "_blank")
              }}/>
            </div>
          </InfoWindow>
        )}
      </>
    )
      ;
  })
;

SingleUserMarker.displayName = "SingleUserMarker"