import React, {memo, useCallback, useState} from "react";
import {useOperationalMapContext} from "../../providers/OperationalMapProvider";
import {AdvancedMarker, InfoWindow, Marker, useAdvancedMarkerRef, useMarkerRef} from "@vis.gl/react-google-maps";
import SearchableSelect, {SearchableSelectOption} from "../../components/form-v2/searchable-select";
import {downloadCSVFile} from "../../util/downloadCSVFile";
import {BlueButton} from "../../components/ui/Buttons";
import moment from "moment/moment";
import {UserMarkerProps} from "./usermarker";

export const MultipleUserMarker: React.FC<UserMarkerProps> = memo((props) => {

  const ctx = useOperationalMapContext();
  const [markerRef, marker] = useMarkerRef();
  const [selected, setSelected] = useState(0)




  const user = props.users[selected]
  const source = user.source === "facility" ? "Stationed Facility" : user.source

  const openInfo = useCallback(() => {
    ctx.setVisibleInfoWindow(props.infoKey);
  }, []);

  const options: SearchableSelectOption<number>[] = props.users.map((u, i) => {
    return {
      value: i,
      key: u._id,
      label: `${u.firstName} ${u.lastName}`,
      search: `${u.firstName} ${u.lastName} ${u.email}`,
      selected: selected === i,
    }
  })

  const [selectedOption, setSelectedOption] = useState<SearchableSelectOption<number>>(options[0])


  const onChange = (v: SearchableSelectOption<number>[]) => {
    setSelected(v[0].value)
    setSelectedOption(v[0])
  }

  const handleDownloadCSV = () => {
    downloadCSVFile(
      `${process.env.REACT_APP_API_URL}users-locations-csv?userIDs=${props.users.map(u => u._id).join(
        ","
      )}`,
      "users.csv"
    );
  }


  return (
    <>
      <Marker
        ref={markerRef}
        position={{lat: props.lat, lng: props.lng }}
        onClick={openInfo}
        icon={{
          url: "/assets/mapicons/group.png",
        }}
      />
      {props.showInfoWindow && (
        <InfoWindow anchor={marker}>
          <h3 className={"text-center text-lg font-semibold"}>User Details</h3>
          <hr/>

          <div className={"text-base mt-4"}>
            <SearchableSelect multiple={false} search={true} options={options} selected={selectedOption ? [selectedOption]: []} localSearch={true} onChange={onChange}/>
          </div>
          <div className={"mt-2 grid grid-cols-[1fr,3fr] gap-x-4"}>
            <div className={"text-base font-semibold"}>Name</div>
            <div className={"text-base capitalize"}>{user.firstName} {user.lastName}</div>

            <div className={"text-base font-semibold"}>Email</div>
            <div className={"text-base capitalize"}>
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </div>

            {user.phoneNumbers && user.phoneNumbers.length != 0 && <><div className={"text-base font-semibold"}>Phone Number/s</div>
                <div className={"text-base capitalize"}>
                  {user.phoneNumbers.map((p, i) => (
                    <a key={i} className={"block"} href={`tel:${p.number}`}>{p.number}</a>
                  ))}
                </div>
            </>}

            <div className={"text-base font-semibold"}>Source</div>
            <div className={"text-base capitalize"}>{source}</div>
          </div>

          <div className={"mt-2 flex flex-col gap-2"}>
            <BlueButton text={"Show World Search"} onClick={() => {
              window.open(`world-search?userID=${user._id}&startDate=${moment().subtract(1, "month").format("YYYY-MM-DD")}&endDate=${moment().add(1, "month").format("YYYY-MM-DD")}`, "_blank")
            }}/>

            <BlueButton text={"Download CSV"} onClick={handleDownloadCSV}/>


          </div>
        </InfoWindow>
      )}

    </>
  );
});

MultipleUserMarker.displayName = "MultipleUserMarker"
